<template>
  <div v-if="loaded" id="select-company-formed">
    <div class="col-md-12 mb-3">
      <h1 class="header-text">
        When did you form your company?
      </h1>
    </div>

    <div class="col-md-12">
      <div class="radio-group-container">
        <div class="radio-option" @click="companyFormed = 'unformed'">
          <b-form-radio v-model="companyFormed" value="unformed" />
          <div class="radio-text">
            <span>I haven't formed my company with the state yet</span>
          </div>
        </div>
        <div class="radio-option d-flex align-items-center" @click="companyFormed = 'formed with date'">
          <b-form-radio v-model="companyFormed" value="formed with date" />
          <div class="text-and-date-container">
            <div class="radio-text">
              <span>I formed my company on</span>
            </div>
            <div class="date-container">
              <b-form-input
                v-model="formationDate"
                type="date"
                :max="dateInputLimit"
                class="formation-date"
              />
            </div>
          </div>
        </div>
        <div class="radio-option" @click="companyFormed = 'formed without date'">
          <b-form-radio v-model="companyFormed" value="formed without date" />
          <div class="radio-text">
            <span>I can't remember my formation date</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <b-button
        class="btn btn-primary submit-button mt-4"
        aria-label="submit button"
        :disabled="!valid"
        @click="submit"
      >
        Continue
      </b-button>
    </div>
  </div>

  <ct-centered-spinner v-else class="mt-5">
    Saving...
  </ct-centered-spinner>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { tagSession } from '@/common/modules/inspectlet'
  export default {
    name: 'SelectCompanyFormed',
    components: {
      CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    },
    data() {
      return {
        companyFormed: null,
        formationDate: null,
        dateInputLimit: this.setDateInputLimit(),
        maxDate: this.setMaxDate(),
        loaded: true,
      }
    },
    computed: {
      ...mapGetters('stageline', [
        'accountCompanies',
        'company',
      ]),
      valid() {
        return this.companyFormed &&
          (this.companyFormed !== 'formed with date' || new Date(this.formationDate) <= this.maxDate)
      },
    },
    watch: {
      companyFormed(newVal) {
        if (newVal !== 'formed with date') this.formationDate = null
      },
    },
    mounted() {
      tagSession({
        stagelineV2: 'decisionTreeSelectCompanyFormed',
        entity_type: this.company?.entity_type,
        companyId: this.company?.id,
      })
    },
    methods: {
      ...mapActions('companies', [
        'updateCompanyConfig',
      ]),
      ...mapActions('dashpanel', [
        'refreshCompaniesRequiringCtaIds',
      ]),
      ...mapActions('stageline', [
        'getAccountCompanies',
        'setCurrentPeriodName',
      ]),
      setDateInputLimit() {
        const today = new Date()
        const year = today.getFullYear()
        const month = ('0' + (today.getMonth() + 1)).slice(-2)
        const day = ('0' + today.getDate()).slice(-2)
        return `${year}-${month}-${day}`
      },
      setMaxDate() {
        const date = new Date()
        return new Date(date.getFullYear(), date.getMonth(), date.getDate())
      },
      async submit() {
        this.loaded = false
        await this.updateCompanyStageline()
        this.loaded = true
      },
      async updateCompanyStageline() {
        await this.updateCompanyConfig({
          companyId: this.company.id,
          config: {
            formed_elsewhere: this.companyFormed && this.companyFormed !== 'unformed',
            formation_date: !this.formationDate ? 'unknown' : this.normalizeDate(this.formationDate),
          },
        })

        // TODO Yucky to fetch all companies again, make this a granular fetch just for the new
        //  company, to pull in its Stageline route
        await this.getAccountCompanies()
        await this.refreshCompaniesRequiringCtaIds()

        const newCompany = this.accountCompanies.find(ac => ac.id === this.company.id)

        // For companies needing CTA, route directly to Stageline and bypass the rest of the Decision Tree
        if (newCompany?.stageline?.route?.status_key === 'needs_corporate_transparency_act_check') {
          this.setCurrentPeriodName('corporate_transparency_act')
          await this.$router.push({ name: 'stageline-v2-start', params: { companyId: this.company.id } })
        } else {
          // If company doesn't need CTA, continue Decision Tree
          this.$emit('next')
        }
      },
      normalizeDate(date) {
        if (date) {
          const tempDateTime = new Date(date)
          const [tempYear, tempMonth, tempDate] = date.split('-')
          return tempDateTime.getFullYear().toString().length === 4 ?
            [tempMonth, tempDate, tempYear].join('/') :
            null
        }
        return null
      },
    },
  }
</script>

<style lang="scss" scoped>
  #select-company-formed {
    height: 100%;
    margin-left: 1.75rem;

    .header-text {
      font-size: 2.25rem;
      font-weight: 700;
      letter-spacing: -0.02em;
      text-align: center;
      margin-bottom: 3rem;
    }
    .radio-group-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: sticky;
      width: 32rem;
      margin: auto;

      .radio-option {
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        text-align: left;
        height: 4rem;
        max-width: 32rem;
        padding: 1rem 3rem 1rem 2rem;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }
      .radio-text {
        display: flex;
        align-items: center;
        padding-left: 1rem;
        flex-grow: 1;
      }
      .date-container {
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        width: 10rem;
        padding: 0 0.5rem;
        margin-left: 1.5rem;

        .formation-date {
          border: none;
          border-radius: 0;
          padding: 0;
          min-height: unset !important;
          height: 2rem;
          width: 9rem;

          &::-webkit-calendar-picker-indicator {
            position: absolute;
            left: 0.75rem;
          }
          &::-webkit-datetime-edit {
            position: relative;
            left: 1rem;
            margin-right: 1rem;
          }
          &::-webkit-datetime-edit-fields-wrapper {
            position: relative;
            left: 1rem;
          }
        }
      }
      .text-and-date-container {
        display: flex;
        flex-direction: row;
      }
    }
    .submit-button {
      float: right;
      border-radius: 4px;
    }
  }

  @media only screen and (max-width: 525px) {
    #select-company-formed {
      .radio-group-container {
        width: 19.5rem;
        margin: auto;

        .radio-option {
          height: 5rem;
          max-width: 19.5rem;
          padding: 1rem 1.5rem 1rem 1rem;

          .radio-text {
            padding-left: 0.5rem;
          }
          .date-container {
            margin-left: 0.75rem;
          }
          .text-and-date-container {
            flex-direction: column;
          }
        }
      }
    }
  }
</style>
